/**
 * @file Header.js
 * @description Componente per la barra di navigazione superiore dell'applicazione. 
 * L'header è destinato a contenere i tasti Login / Signup e Profilo utente e impostazioni varie in base allo stato di autenticazione dell'utente.
 * Utilizza il contesto di autenticazione `AuthContext` per determinare se un utente è loggato e quale ruolo possiede.
 */

/**
 * Facciamo in modo che cliccando su una voce di menu, se siamo già presenti su una pagina, la pagina viene ricaricata.
 * Esempio: se ci troviamo su /home e nel menu clicchiamo /home allora la pagina deve eseguire il refresh.
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../assets/css/header.css';


/**
 * Componente di navigazione laterale che mostra i link principali dell'applicazione.
 * 
 * @component
 * @returns {JSX.Element} Header con i link pertinenti in base allo stato dell'utente.
 * - Mostra link di registrazione e login se l'utente non è autenticato.
 * - Mostra i link all'area admin se l'utente autenticato è un admin.
 * - Mostra il pulsante di logout per utenti autenticati.
 */
const Header = () => {
   const { user, logout } = useAuth();
   const navigate = useNavigate();

   return (
      <nav class="header" role="navigation" aria-label="Header Principale">

         <div class="user_menu">
            {!user && (
               <div class="login_signup">
                  <button class="item_menu signup_button" onClick={() => navigate('/signup')}>Registrati</button>
                  <button class="item_menu login_button" onClick={() => navigate('/login')}>Accedi</button>
               </div>
            )}
            {user && user.role === 'admin' && (
               <div class="admin_menu">
                  <div class="item_menu"><Link to="/admin">Admin Dashboard</Link></div>
                  <div class="item_menu"><Link to="/newdoc">Scrivi Articolo</Link></div>
                  <div class="item_menu"><Link to="/newsletter">Scrivi Newsletter</Link></div>
               </div>
            )}
            {user && (
               <div class="logged_in_menu">
                  <div class="item_menu"><Link to="/profiloutente">Profilo Utente</Link></div>
                  <div class="item_menu"><button onClick={logout}>Logout</button></div>
               </div>
            )}
         </div>

      </nav>
   );
};

export default Header;