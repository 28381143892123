/**
 * @file ProtectedRoute.js
 * @description Componente per gestire le rotte protette dell'applicazione, consentendo l'accesso solo agli utenti con ruoli specifici.
 * Questo componente verifica l'autorizzazione dell'utente basandosi sul token di accesso e sul ruolo, garantendo che solo gli utenti autorizzati possano accedere alle pagine protette.
 */

import React from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

/**
 * Componente che gestisce l'accesso alle rotte protette dell'applicazione.
 * 
 * @component
 * @param {Object} props - Proprietà del componente.
 * @param {React.Component} props.element - Il componente da renderizzare se l'accesso è consentito.
 * @param {Array<string>} props.allowedRoles - Lista dei ruoli autorizzati ad accedere alla rotta.
 * @returns {JSX.Element} Restituisce il componente protetto se l'utente è autorizzato, altrimenti reindirizza alla pagina di login.
 */
const ProtectedRoute = ({ element: Element, allowedRoles }) => {
  
  // Stato per determinare se l'utente è autorizzato
  const [isAuthorized, setIsAuthorized] = React.useState(null);

  React.useEffect(() => {
    const verifyAccess = async () => {
      try {
        // Richiedi la verifica del token di accesso al backend
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/verify-token`, {
          withCredentials: true
        });
        if (response.status === 200 && response.data.loggedIn) {
          // Controlla se l'utente ha uno dei ruoli consentiti
          const userRole = response.data.role;
          if (allowedRoles.includes(userRole)) {
            setIsAuthorized(true);
          } else {
            setIsAuthorized(false);
          }
        } else {
          setIsAuthorized(false);
        }
      } catch (error) {
        console.error('Errore durante la verifica dell\'accesso:', error);
        setIsAuthorized(false);
      }
    };

    verifyAccess();
  }, [allowedRoles]);

  // Ritorna null finché non si conosce lo stato di autorizzazione
  if (isAuthorized === null) {
    return <div>Loading...</div>;
  }

  return isAuthorized ? <Element /> : <Navigate to="/login" />;
};

export default ProtectedRoute;