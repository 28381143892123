
/**
 * @file Sidenav.js
 * @description Componente per la barra di navigazione laterale dell'applicazione. 
 * La barra di navigazione mostra link diversi in base allo stato di autenticazione dell'utente, inclusi link pubblici e aree riservate agli utenti autenticati.
 * Utilizza il contesto di autenticazione `AuthContext` per determinare se un utente è loggato e quale ruolo possiede.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../assets/css/sidenav.css';

/**
 * Componente di navigazione laterale che mostra i link principali dell'applicazione.
 * 
 * @component
 * @returns {JSX.Element} La barra di navigazione con i link pertinenti in base allo stato dell'utente.
 * - Mostra link di registrazione e login se l'utente non è autenticato.
 * - Mostra il link all'area admin se l'utente autenticato è un admin.
 * - Mostra il pulsante di logout per utenti autenticati.
 */
const Sidenav = () => {
   const { user, logout } = useAuth();

   return (
      <nav class="sidenav" role="navigation" aria-label="Menu Principale">

         <div class="docs_menu">
            <div class="item_menu"><Link to="/">Home</Link></div>
            <div class="item_menu"><Link to="/about">About</Link></div>
            {!user && (
               <div>
                  <div class="item_menu"><Link to="/signup">Signup</Link></div>
                  <div class="item_menu"><Link to="/login">Login</Link></div>
               </div>
            )}
            {user && user.role === 'admin' && (
               <div class="item_menu"><Link to="/admin">Admin Area</Link></div>
            )}
            {user && (
               <div class="item_menu"><button onClick={logout}>Logout</button></div>
            )}
         </div>

      </nav>
   );
};

export default Sidenav;